<template>
  <div>
    <div class="box1">
      <img
       @click="onClickLeft"
        class="icon"
        referrerpolicy="no-referrer"
        src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng7c651225ed1e60046aa1f5fd66c992f667a964616d45f55d20a9987d58547676"
      />
    </div>
    <div class="box2 flex-row">
      <span class="word1">已有账号直接去登录</span>
    </div>
     <div class="box3 flex-col">
      <div class="section1 flex-col justify-between">
        <img
          class="pic1"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng6c25d5c1d814bb37cdc45e370c47d028cf621d73933e0f17c4bb940183dddb1a"
        />
        <span class="info2">Win生活</span>
      </div>
    </div>
     <div class="box4">
      <span class="info3">优</span> <span class="word2">质</span>
      <span class="word3">生活</span> <span class="txt1">多</span>
      <span class="info4">亿</span> <span class="word4">点</span>
    </div>
    <div class="box5 flex-col"></div>
    <div class="box6 flex-col"><span class="info5">立即注册</span></div>
  </div>
</template>

<script>

export default {
  data() {
    return {

    }
  },
 
  methods: {
   
     // 导航返回
    onClickLeft() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        window.android.close();
      }
      // console.log('返回');
      // this.$router.go(-1)
    },
  }
};
</script>

<style lang="less" scoped>
.box1 {
  height: 227px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng3259ea740bb903ce947a9f003cdc1ab63daa07e9a76af646af57a2a9dd6144f0)
    100% no-repeat;
  width: 277px;
  .icon {
    width: 14px;
    height: 14px;
    margin-top: 27px;
    margin-left: 20px;
  }
}

.box2 {
  width: 135px;
  height: 21px;
  margin: 414px 0 150px 120px;
  .word1 {
  width: 135px;
  height: 21px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 15px;
  text-decoration: underline;
  white-space: nowrap;
  line-height: 21px;
  text-align: left;
}
}

.box3 {
  z-index: 4;
  height: 399px;
  border-radius: 50%;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng6ef3ace983e94787682525c1d0c1cc5200164dc87cfc15689e37182b08f37b7d) -108px -38px
    no-repeat;
  width: 399px;
  position: absolute;
  left: 132px;
  top: 38px;
  .section1 {
  width: 109px;
  height: 143px;
  margin: 123px 0 0 1px;
}

.pic1 {
  width: 109px;
  height: 109px;
}

.info2 {
  width: 72px;
  height: 25px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  white-space: nowrap;
  line-height: 25px;
  text-align: left;
  margin: 9px 0 0 19px;
}
}

.box4 {
  position: absolute;
  left: 71px;
  top: 391px;
  width: 233px;
  height: 61px;
  overflow-wrap: break-word;
  text-align: left;
  white-space: nowrap;
  font-size: 0;
  .info3 {
  font-size: 42px;
  font-family: PingFangSC-Medium;
  color: rgba(245, 37, 86, 1);
  line-height: 59px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.word2 {
  font-size: 26px;
  font-family: PingFangSC-Medium;
  color: rgba(102, 102, 102, 1);
  line-height: 59px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.word3 {
  font-size: 30px;
  font-family: PingFangSC-Medium;
  color: rgba(102, 102, 102, 1);
  line-height: 59px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.txt1 {
  font-size: 22px;
  font-family: PingFangSC-Medium;
  color: rgba(102, 102, 102, 1);
  line-height: 59px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info4 {
  font-size: 43px;
  font-family: PingFangSC-Medium;
  color: rgba(249, 69, 111, 1);
  line-height: 59px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.word4 {
  font-size: 26px;
  font-family: PingFangSC-Medium;
  color: rgba(102, 102, 102, 1);
  line-height: 59px;
  overflow: hidden;
  text-overflow: ellipsis;
}
}

.box5 {
  z-index: 3;
  position: absolute;
  left: -156px;
  top: 382px;
  width: 239px;
  height: 239px;
  border-radius: 50%;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng7cba5947fe7d8207c53ac79542662df9f831a8361793136d687e9f30f43ebc0d)
    156px -69px no-repeat;
}

.box6 {
  z-index: 5;
  height: 48px;
  border-radius: 10px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng3966355b7c89b8e29247c38b2627fa79afb74ec6b0ce7d4efa0fa8f4a978c993)
    100% no-repeat;
  width: 310px;
  position: absolute;
  left: 33px;
  top: 573px;
  .info5 {
  width: 68px;
  height: 24px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 17px;
  white-space: nowrap;
  line-height: 24px;
  text-align: left;
  margin: 12px 0 0 121px;
}
}




</style>
